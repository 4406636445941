import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    container, stylizationHeader, backProfile, arrowBackProfile, formTop, formBottom,
    title, formBreak, formButton, inputTop, textBackProfile, occasionWrapper, occasionItem,
    titleWrapper, postCardContent, titleContent, imgBox, ratio
} from './generator-start-fields.module.scss';
import Arrow from "../../../assets/images/svg/arrow.svg";
import { priceFilters, selectFiltersConfigs } from "../config";
import { TGeneratorFilters } from "../../../models/generator.model";
import { getAbsolutePath } from "../../utility/routes.utility";

import Title from "../../../components/atoms/title";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/form/input";
import Select from "../../../components/atoms/form/select";
import { useField, useFormikContext } from "formik";
import { TOption } from "../../../models/option.model";
import RatioImage from "../../../components/atoms/ratio-image";

interface IGeneratorStartFieldsProps {
    className?: string;
    filters: TGeneratorFilters;
}

const GeneratorStartFields: React.FC<IGeneratorStartFieldsProps> = ({
    className = '',
    filters,
}) => {
    const { t } = useI18next();
    const [field, meta, helpers] = useField('occasion');
    const [fieldName, metaName, helpersName] = useField('name');
    const { submitForm } = useFormikContext();

    const [occasionImages, setOccasionImages] = useState<string[]>([]);


    useEffect(() => {
        console.log('GeneratorStartFields rendered');
        console.log('filters', filters["occasion"]);
    }, []);
    useEffect(() => {
        console.log('filters', filters);
        const loadImages = async () => {
            const images: string[] = await Promise.all(
                filters['occasion'].map(async (option) => {
                    const image = await import(`../../../assets/images/occasion/${option.value || option.id}.jpg`);
                    return image.default;
                })
            );
            setOccasionImages(images);
        };
        loadImages();
    }, [JSON.stringify(filters)]);

    const occasionSelect = (option: TOption) => {
        helpers.setValue(option.value || option.id);
        helpersName.setValue(option.label);
        setTimeout(() => {
            submitForm();
        }, 20);
    }

    return (
        <div className={`${className} ${container}`}>
            <div className={stylizationHeader}>
                {t('generator.title')}
            </div>
            {/* <Title
                className={title}
                fontStyle="italic"
                size="medium"
                subtitle={t('generator.subtitle')}
                align="center"
            >
                {t('generator.title')}
            </Title> */}
            <div className={occasionWrapper}>
                {filters['occasion']
                .slice()
                .sort((a, b) => {
                    if (a.label === "Oczaruj mnie") return 1;  // Przesuń "Oczaruj mnie" na koniec
                    if (b.label === "Oczaruj mnie") return -1; // Przesuń "Oczaruj mnie" na koniec
                    return a.label.localeCompare(b.label); // Standardowe sortowanie alfabetyczne
                }).map((option, index) => {
                   const imageSrc = occasionImages[index % occasionImages.length];
                   return (
                        <div className={occasionItem} key={(option.value || option.id)} onClick={() => occasionSelect(option)}>
                            {/* <RatioImage
                                image={'../../../assets/images/occasion/1.jpg'}
                                alt={option.label}
                                className={imgBox}
                                ratioClass={ratio}
                            /> */}
                            <img src={imageSrc} />
                            <div className={titleWrapper}>
                                <div className={postCardContent}>
                                    <h3 className={titleContent}>{option.label}</h3>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className={formTop}>
                <Input
                    name="name"
                    type="text"
                    placeholder={t('generator.form.placeholder.name')}
                    label={t('generator.form.label.name')}
                    className={inputTop}
                    theme="transparent"
                    showLabel={true}
                />
                {selectFiltersConfigs.map((config) => {
                    const { isMultiselect, filterKey } = config;
                    return (
                        <Select
                            key={filterKey}
                            options={filters[filterKey]}
                            name={filterKey}
                            label={t(`generator.form.label.${filterKey}`)}
                            placeholder={t(`generator.form.placeholder.${filterKey}`)}
                            isMultiselect={isMultiselect}
                            showCounter={isMultiselect}
                            colorTheme="light"
                            showLabel={true}
                        />
                    );
                })}
            </div>
            {/* <p className={formBreak}>{t('generator.prices')}</p> */}
            <div className={formBottom}>
                {priceFilters.map((filterKey) => {
                    return (
                        <Select
                            key={filterKey}
                            options={filters[filterKey]}
                            name={filterKey}
                            colorTheme="light"
                            label={t(`generator.form.label.${filterKey}`)}
                            placeholder={t(`generator.form.placeholder.${filterKey}`)}
                            showLabel={true}
                        />
                    );
                })}
            </div>
            <Button
                htmlType="submit"
                type="filled"
                color="primary"
                className={formButton}
            >
                {t('generator.button.generate')}
            </Button>
            {/* <Link
                to={getAbsolutePath('ACCOUNT_STYLIZATIONS')}
                className={backProfile}
            >
                <Arrow className={arrowBackProfile} />
                <p className={textBackProfile}>{t('generator.back')}</p>
            </Link> */}
        </div>
    );
};

export default GeneratorStartFields;